import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DocsLayout from '../../layouts/DocsLayout';
import { Grid, Column } from 'src/components/molecules/Grid';
import { Section } from 'src/components/molecules/Section';
import { DocsPanel } from 'src/components/molecules/DocsPanel';
import { NewsletterForm } from 'src/components/molecules/NewsletterForm';
import { Link } from 'src/containers/Link';
import { Logo } from 'src/containers/Logo.new';
import { DocsBlogPosts } from 'src/containers/DocsBlogPosts';
import { Image } from 'src/containers/Image2';
import { GuideList, GuideListItem } from 'src/components/atoms/GuideList';
import { List, ListItem } from 'src/components/atoms/List';
import { Icon } from 'src/components/atoms/Icon';
import { TeaserList, TeaserListItem } from 'src/components/molecules/TeaserList';
import { MainTitle, MainSubTitle } from 'src/components/molecules/DocsTitle';
import { graphql } from 'gatsby';
export const pageQuery = graphql`
  query {
    n26: file(relativePath: { eq: "logos/n26_logo.svg" }) {
      ...LogoImageDataNoSharp
    }
    uniqueai: file(relativePath: { eq: "logos/showcase/uniqueai_logo.png" }) {
      ...LogoImageData
    }
    adobe: file(relativePath: { eq: "logos/adobe_logo.svg" }) {
      ...LogoImageDataNoSharp
    }
    iconRocketSvg: file(relativePath: {eq:"docs/icon-rocket.svg"}) {
      ...Image2NoSharp
    }
    iconOpenSourceSvg: file(relativePath: {eq:"docs/icon-open-source.svg"}) {
      ...Image2NoSharp
    }
    iconRasaXPng: file(relativePath: {eq:"docs/icon-rasa-x.png"}) {
      ...Image2
    }
    tutorialPng: file(relativePath: {eq:"docs/tutorial.png"}) {
      ...Image2
    }
    iconSaraHatSvg: file(relativePath: {eq:"docs/icon-sara-hat.svg"}) {
      ...Image2NoSharp
    }
    iconBookSvg: file(relativePath: {eq:"docs/icon-book.svg"}) {
      ...Image2NoSharp
    }
    iconBookSvg: file(relativePath: {eq:"docs/icon-book.svg"}) {
      ...Image2NoSharp
    }
    youtubeThumbLivecodingPng: file(relativePath: {eq:"docs/youtube-thumb-livecoding.png"}) {
      ...Image2
    }
    youtubeThumbAlgorithmsPng: file(relativePath: {eq:"docs/youtube-thumb-algorithms.png"}) {
      ...Image2
    }
    iconQuestionSvg: file(relativePath: {eq:"docs/icon-question.svg"}) {
      ...Image2NoSharp
    }
    iconDiscourseSvg: file(relativePath: {eq:"docs/icon-discourse.svg"}) {
      ...Image2NoSharp
    }
    iconResearchSvg: file(relativePath: {eq:"docs/icon-research.svg"}) {
      ...Image2NoSharp
    }
    iconLightbulbSvg: file(relativePath: {eq:"docs/icon-lightbulb.svg"}) {
      ...Image2NoSharp
    }
    showcaseN26Png: file(relativePath: {eq:"docs/showcase/n26.png"}) {
      ...Image2
    }
    showcaseUniqueAiPng: file(relativePath: {eq:"docs/showcase/unique-ai.png"}) {
      ...Image2
    }
    showcaseAdobeSenseiPng: file(relativePath: {eq:"docs/showcase/adobe-sensei.png"}) {
      ...Image2
    }
    iconViewDocsSvg: file(relativePath: {eq:"docs/icon-view-docs.svg"}) {
      ...Image2
    }
    iconRasaDocsSvg: file(relativePath: {eq:"docs/icon-rasa-docs.svg"}) {
      ...Image2
    }
    iconStudioDocsSvg: file(relativePath: {eq:"docs/icon-studio-docs.png"}) {
      ...Image2
    }
    iconGetStartedSvg: file(relativePath: {eq:"docs/icon-get-started.svg"}) {
      ...Image2
    }
    iconLearnSvg: file(relativePath: {eq:"docs/icon-learn.svg"}) {
      ...Image2
    }
    iconRasaEnterpriseDocsSvg: file(relativePath: {eq:"docs/icon-rasa-enterprise-docs.svg"}) {
      ...Image2
    }
    iconSupportSvg: file(relativePath: {eq:"docs/icon-support.svg"}) {
      ...Image2
    }
  }
`;
export const _frontmatter = {
  "title": "This should be the title of the dev portal",
  "subtitle": "This is the description of the dev portal"
};
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DocsLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Section maxWidth="xlarge" centered mdxType="Section">
  <MainTitle mdxType="MainTitle">Documentation</MainTitle>
  <MainSubTitle mdxType="MainSubTitle">Welcome! Here you can access the complete documentation for the Rasa Platform.</MainSubTitle>
      <DocsPanel icon={<Image data={props.data.iconViewDocsSvg} width="47" height="47" role="presentation" noMargin mdxType="Image" />} title="View the docs" subtitle="See the documentation for each product." mdxType="DocsPanel">
        <Grid tiny columns="1fr 1fr" noMargin verticalAlign="middle" mdxType="Grid">
          <DocsPanel to="/docs/rasa-pro/" icon={<Image data={props.data.iconRasaDocsSvg} width="47" height="47" role="presentation" noMargin mdxType="Image" />} title="Rasa Pro" subtitle="The enterprise grade generative conversational AI framework." noMarginBottom mdxType="DocsPanel" />
          <DocsPanel to="/docs/studio/" icon={<Image data={props.data.iconStudioDocsSvg} width="47" height="47" role="presentation" noMargin mdxType="Image" />} title="Rasa Studio" subtitle="No-code user interface to build and optimize your assistant at scale." noMarginBottom mdxType="DocsPanel" />
          <DocsPanel to="/docs/rasa/" icon={<Image data={props.data.iconRasaDocsSvg} width="47" height="47" role="presentation" noMargin mdxType="Image" />} title="Rasa Open Source" subtitle="The open source framework for building text- and voice-based applications." noMarginBottom mdxType="DocsPanel" />
          <DocsPanel to="/docs/rasa-enterprise/" title="Rasa X/Enterprise" icon={<Image data={props.data.iconRasaEnterpriseDocsSvg} width="47" height="47" role="presentation" noMargin mdxType="Image" />} subtitle="Web application to review and improve your assistant at scale." noMarginBottom mdxType="DocsPanel" />
        </Grid>
      </DocsPanel>
      <Grid tiny columns="1fr 1fr 1fr" mdxType="Grid">
        <Column mdxType="Column">
          <DocsPanel icon={<Image data={props.data.iconLearnSvg} width="47" height="47" role="presentation" noMargin mdxType="Image" />} title="Learn about Rasa products" subtitle="Read more about each product." mdxType="DocsPanel">
            <List mdxType="List">
  <ListItem mdxType="ListItem">
    <Link href="https://rasa.com/docs/rasa-pro/" mdxType="Link">
      Introduction to Rasa Pro
    </Link>
  </ListItem>
  <ListItem mdxType="ListItem">
    <Link href="https://rasa.com/docs/studio/" mdxType="Link">
      Introduction to Rasa Studio
    </Link>
  </ListItem>
  <ListItem mdxType="ListItem">
    <Link href="https://rasa.com/docs/rasa/" mdxType="Link">
      Introduction to Rasa Open Source
    </Link>
  </ListItem>
  <ListItem mdxType="ListItem">
    <Link href="https://rasa.com/docs/rasa-enterprise/" mdxType="Link">
      Introduction to Rasa X/Enterprise
    </Link>
  </ListItem>
            </List>
          </DocsPanel>
        </Column>
        <Column mdxType="Column">
          <DocsPanel icon={<Image data={props.data.iconGetStartedSvg} width="47" height="47" role="presentation" noMargin mdxType="Image" />} title="Get started" subtitle="Ready to get started? Start here." mdxType="DocsPanel">
            <List mdxType="List">
  <ListItem left="1.15rem" mdxType="ListItem">
    <Link href="https://rasa.com/docs/rasa-pro/installation/python/environment-set-up" mdxType="Link">
      Install Rasa Pro
    </Link>
  </ListItem>
  <ListItem left="1.15rem" mdxType="ListItem">
    <Link href="https://rasa.com/docs/rasa/installation/environment-set-up" mdxType="Link">
      Install Rasa Open Source
    </Link>
  </ListItem>
  <ListItem left="1.15rem" mdxType="ListItem">
    <Link href="https://rasa.com/docs/rasa-enterprise/installation-and-setup/installation-guide" mdxType="Link">
      Install Rasa X/Enterprise
    </Link>
  </ListItem>
            </List>
          </DocsPanel>
        </Column>
        <Column mdxType="Column">
          <DocsPanel icon={<Image data={props.data.iconSupportSvg} width="47" height="47" role="presentation" noMargin mdxType="Image" />} title="Need help?" subtitle="Get support tailored to your needs." mdxType="DocsPanel">
            <List mdxType="List">
  <ListItem left="1.15rem" mdxType="ListItem">
    <Link href="https://forum.rasa.com/" allowExternalLinkIcon mdxType="Link">
      Community Support
    </Link>
  </ListItem>
  <ListItem left="1.15rem" mdxType="ListItem">
    <Link href="https://rasa.com/product/support/" allowExternalLinkIcon mdxType="Link">
      Enterprise-grade Support
    </Link>
  </ListItem>
  <ListItem left="1.15rem" mdxType="ListItem">
    <Link href="https://learning.rasa.com" allowExternalLinkIcon mdxType="Link">
      Rasa Learning Center
    </Link>
  </ListItem>
            </List>
          </DocsPanel>
        </Column>
      </Grid>
    </Section>



    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      